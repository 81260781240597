<style>

.selectSociety {
    width: 455px !important;
    /* max-width: fit-content; */
}
</style>
<template>
    <div>
        <!-- Form Modal -->
        <b-modal id="modal-season-form" ref="myModalRoute" centered no-close-on-backdrop no-close-on-esc size="md"
            @ok="submitRoute">
            <!-- Custom Modal Header -->
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <h5 v-if="route.id === null">Add Route</h5>
                <h5 v-if="route.id !== null">Edit Route</h5>

                <feather-icon class="ml-4 pr-0 cursor-pointer" icon="XIcon" size="16" @click="close()" />
            </template>

            <!-- Custom Modal Footer -->
            <template #modal-footer="{ ok, cancel }">
                <b-button size="md" variant="primary" @click="ok()" :disabled="saving">
                    <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                    <span v-if="!saving">Save</span>
                    <span v-if="saving">Saving...</span>
                </b-button>
                <b-button size="md" @click="cancel()" variant="outline-secondary">
                    Cancel
                </b-button>
            </template>

            <!-- Data Form in Modal Body -->
            <template #default="{ }">
                <validation-observer ref="dataForm" #default="{ invalid }">
                    <b-form ref="form" @submit.stop.prevent="handleCreateRoute">
                        <b-row>
                            <b-col cols="12">
                                <b-form-checkbox :checked="route.sourceType" class="custom-control-success"
                                    name="check-button" switch v-model="route.sourceType" value="1" unchecked-value="0">
                                    <span class="switch-icon-left">
                                        <feather-icon icon="CheckCircleIcon" />
                                    </span>
                                    <span>Is from Farmers?</span>
                                </b-form-checkbox>
                            </b-col>
                        </b-row>
                        <b-row v-if="route.sourceType == 1">
                            <b-col cols="12">
                                <b-form-group labe-for="source_id" label="Source Sub Zone">
                                    <b-form-select
                                        name="source_id"
                                        id="source_id"
                                        size="md"
                                        v-model="route.source_id"
                                    >
                                        <b-form-select-option :value="null">-- Select --</b-form-select-option>
                                        <b-form-select-option v-for="society in societies" :key="society.id" :value="society.id">
                                            {{ society.name }}
                                        </b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="12" v-if="route.sourceType == 0">
                                <b-form-group labe-for="source_id" label="Source CPP">
                                    <Dropdown
                                        name="source_id"
                                        id="source_id"
                                        v-model="route.source_id"
                                        :options="warehouses"
                                        filter
                                        optionLabel="name"
                                        optionValue="id"
                                        placeholder="Source CPP"
                                        class="w-full md:w-14rem selectSociety"
                                    >
                                        <template #option="slotProps">
                                            <div class="flex align-items-center">
                                                <div>{{ slotProps.option.code }}: {{ slotProps.option.name }}</div>
                                            </div>
                                        </template>
                                    </Dropdown>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12">
                                <b-form-group labe-for="destination_id" label="Destination">
                                   <Dropdown
                                        name="destination_id"
                                        id="destination_id"
                                        v-model="route.destination_id"
                                        :options="warehouses"
                                        filter
                                        optionLabel="name"
                                        optionValue="id"
                                        placeholder="Destination"
                                        class="w-full md:w-14rem selectSociety"
                                    >
                                        <template #option="slotProps">
                                            <div class="flex align-items-center">
                                                <div>{{ slotProps.option.name }}  ({{ slotProps.option.code }})</div>
                                            </div>
                                        </template>
                                    </Dropdown>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="12">
                                <b-form-group labe-for="Transaction" label="Transaction Type">
                                    <b-form-select size="md" v-model="route.document_id">
                                        <b-form-select-option :value="null">-- Select --</b-form-select-option>
                                        <b-form-select-option v-for="document in documents" :key="document.id"
                                            :value="document.id">
                                            {{ document.code }} ({{ document.description }})
                                        </b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="12">
                                <b-form-group labe-for="distance" label="Distance in KM">
                                    <validation-provider #default="{ errors }" name="distance" rules="required">
                                        <b-form-input id="distance" name="distance" type="number"
                                            v-model="route.distance" :state="errors.length > 0 ? false : null" size="md"
                                            value="" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.distance">{{
                serverErrors.distance[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>


                        <!-- <b-row>
                            <b-col cols="12">
                                <b-form-group labe-for="rate" label="Rate">
                                    <validation-provider #default="{ errors }" name="rate" rules="required">
                                        <b-form-input id="rate" name="rate" type="number" v-model="route.rate"
                                            :state="errors.length > 0 ? false : null" size="md" value="" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.rate">{{
                serverErrors.rate[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>


                        <b-row>
                            <b-col cols="12">
                                <b-form-group labe-for="multiplyFactor" label="Multiply Factor">
                                    <validation-provider #default="{ errors }" name="multiplyFactor" rules="required">
                                        <b-form-input id="multiplyFactor" name="multiplyFactor" type="number"
                                            v-model="route.multiplyFactor" :state="errors.length > 0 ? false : null"
                                            size="md" value="" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.multiplyFactor">{{
                serverErrors.multiplyFactor[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row> -->

                    </b-form>
                    <!-- <pre>{{ route }}</pre> -->

                </validation-observer>
            </template>
        </b-modal>
        <!-- ./Form Modal -->

        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50 select-size-sm" />
                        <label>entries</label>
                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search & Button -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..."
                                size="md" />
                            <b-button variant="primary" size="md" @click="invokeRoutesForm()">
                                <span class="text-nowrap">Add Route</span>
                            </b-button>
                        </div>
                    </b-col>
                    <!-- ./Search & Button-->
                </b-row>
                <!-- ./Table Top -->
            </div>

            <!-- Table -->
            <b-table striped hover small ref="records" class="position-relative" :items="fetch" responsive
                :fields="columns" primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No records found"
                :sort-desc.sync="isSortDirDesc">

                <template #cell(source)="data">
                    {{ data.item.source.name.toUpperCase() }}
                </template>

                <template #cell(destination)="data">
                    {{ data.item.destination.name.toUpperCase() }}
                </template>




                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template>

                        <b-dropdown-item @click="invokeUpdateForm(data.item)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="confirmDelete(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>

            <!-- Table Footer -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage" first-number
                            last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
    BForm, BFormGroup,
    BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem,
    BSpinner, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import store from '@/store'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import routesStoreModule from '@/views/cromis/warehouse/route/routesStoreModule'
import useRoutesList from '@/views/cromis/warehouse/route/useRoutesList'
import Dropdown from 'primevue/dropdown';

export default {
    props: {},
    components: {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem,
        BSpinner,
        ValidationObserver, ValidationProvider, BFormCheckbox, Dropdown
    },
    directives: {},
    setup(props, context) {
        const dataForm = ref(null)
        const myModal = ref(null)
        const myModalRoute = ref(null)
        const saving = ref(false)
        const serverErrors = ref(null)
        const selections = ref([])
        const formSelections = ref([])
        const levels = ref(null)
        const children = ref([])
        const warehouses = ref([])
        const societies = ref([])
        const documents = ref([])
        const selectedCompany = ref(null)


        const route = ref({
            id: null,
            source_id: null,
            destination_id: null,
            distance: null,
            name: null,
            document_id: null,
            sourceType: 0,
            // rate: null,
            // multiplyFactor: null
        })

        const CROMIS_STORE_MODULE_NAME = 'cromis-route'

        // Register module
        if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, routesStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
        })

        onMounted(async () => {

            await store.dispatch('cromis-route/warehouses')
                .then(response => {
                    warehouses.value = response.data.warehouses
                    console.log(warehouses.value)
                })
                .catch(error => {
                    console.log('got error', error)
                })

            await store.dispatch('cromis-route/societies')
                .then(response => {
                    societies.value = response.data.societies
                   
                })
                .catch(error => {
                    console.log('got error', error)
                })
            await store.dispatch('cromis-route/documents')
                .then(response => {
                    response.data.documents.forEach(doc => {
                        if (doc.display) {
                            documents.value.push(doc)
                        }
                    });

                   
                })
                .catch(error => {
                    console.log('got error', error)
                })

        })



        const {
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,

            // Filers
            locationFilter,
        } = useRoutesList()

        // Form Methods
        const invokeUpdateForm = (item) => {
            serverErrors.value = null
           
            route.value = {
                id: item.id,
                source_id: item.source.id,
                destination_id: item.destination.id,
                distance: item.distance,
                name: item.name,
                sourceType: item.source.sourceType,
                document_id: item.transaction.id,
                // rate: item.rate,
                // multiplyFactor: item.multiplyFactor
            }

            myModalRoute.value.show()
        }

        const invokeRoutesForm = () => {
            serverErrors.value = null

            route.value = {
                id: null,
                source_id: null,
                destination_id: null,
                distance: null,
                name: null,
                sourceType: 0,
                document_id: null,
                // rate: null,
                // multiplyFactor: null
            }

            myModalRoute.value.show()
        }

        const isFormValid = async () => {
            let isValid = false
            await dataForm.value.validate().then(success => {
                isValid = success
            })

            return isValid
        }

        const submit = (bvModalEvt) => {
            bvModalEvt.preventDefault()


            serverErrors.value = null
            // Handle form submit
            if (route.value.id === null || route.value.id === 0)
                handleCreate()
            else
                handleUpdate(sector.value)
        }

        const handleCreate = async () => {
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }



        }


        const submitRoute = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null

            if (route.value.id === null || route.value.id === 0)
                handelRouteCreate()
            else
                handleUpdate(route.value)

        }

        const handleUpdate = async (item) => {
            saving.value = true
            const valid = await isFormValid()
            console.log(item)
            if (!valid) {
                saving.value = false
                return
            }

            await store.dispatch('cromis-route/update', { id: item.id, data: item })
                .then(response => {

                    console.log(response)
                    refetch()
                    saving.value = false

                    myModalRoute.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Changes to Routes has been saved successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    console.log(error)
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })

        }

        const handelRouteCreate = async () => {

            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }
            route.value.sourceType = parseInt(route.value.sourceType)
            console.log(route.value.sourceType)

            await store.dispatch('cromis-route/create', route.value)
                .then(response => {
                    refetch()
                    saving.value = false

                    context.root.$swal({
                        icon: 'success',
                        text: 'The Route has been added successfully!',
                        showConfirmButton: true,
                        timer: 5000,
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                    route.value = {
                        id: null,
                        source_id: null,
                        destination_id: null,
                        distance: null,
                    }
                    myModalRoute.value.hide()
                })
                .catch(error => {
                    console.log(error)
                    saving.value = false
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }


        const confirmDelete = (item) => {
            context.root.$swal({
                title: 'Are you sure?',
                text: `Route  will be deleted.`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                customClass: {
                    confirmButton: 'btn btn-outline-danger',
                    cancelButton: 'btn btn-outline-primary ml-1',
                },
                buttonsStyling: false,
            }).then(async result => {
                if (result.value) {
                    let success = await remove(item)

                    if (success) {
                        context.root.$swal({
                            icon: 'info',
                            title: 'Deleted!',
                            text: 'Route has been deleted.',
                            customClass: {
                                confirmButton: 'btn btn-outline-info',
                            },
                        })
                    }
                }
            })
        }

        const remove = async (id) => {
            let success = false

            await store.dispatch('cromis-route/remove', id)
                .then(response => {
                    success = true
                    refetch()
                })
                .catch(error => {
                    success = false
                    context.root.$swal({
                        icon: 'error',
                        title: 'Server Error',
                        text: 'Something went wrong. See tech support',
                        showConfirmButton: false,
                        customClass: {
                            confirmButton: 'btn btn-danger',
                        },
                        buttonsStyling: false,
                    })
                })

            return success
        }

        return {
            // Data
            selections,
            formSelections,
            levels,
            children,
            route,
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,

            // Methods
            required,
            saving,
            serverErrors,
            dataForm,
            myModal,
            myModalRoute,
            locationFilter,
            invokeUpdateForm,
            submit,
            submitRoute,
            isFormValid,
            handleCreate,
            handleUpdate,
            remove,
            confirmDelete,
            warehouses,
            selectedCompany,
            invokeRoutesForm,
            documents,
            societies
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>